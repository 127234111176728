body {
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
}

.pac-container {
  z-index: 3000;
}
